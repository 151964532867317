import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

import { Button, IconButton, TextInput } from "./Components";
import "./IconLibrary";

import ar from "./ar";

import axios from "axios";

import QRCode from "qrcode.react";

const File = ({ type = "" }) => {
  return (
    <div className="row">
      <Button style={{ width: "100px" }}>{`Upload .${type}`}</Button>
      <div className="m-4" />
      <IconButton icon="eye" />
      <div className="m-4" />
      <IconButton icon="download" />
      <div className="m-4" />
      <IconButton icon="trash-alt" />
    </div>
  );
};

const ProjectAdd = () => {
  const history = useHistory();

  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const goBack = () => {
    history.location.state ? history.goBack() : history.replace("/projects");
  };

  const submit = async () => {
    setError("");
    try {
      if (!name) {
        setError("Name required");
        return;
      }
      await ar.post("project", { name });
      goBack();
    } catch (err) {
      console.log(err);
      setError("Project already exists");
    }
  };

  return (
    <div className="d-flex col start f-1">
      <div className="title">New Project</div>
      <div className="container col">
        <TextInput
          placeholder="Name"
          value={name}
          onChange={e => setName(e.target.value.toLowerCase())}
          onKeyPress={e => {
            if (e.key === "Enter") {
              submit();
            }
          }}
          autoFocus={true}
        />
        <div className="m-4" />
        {error ? (
          <>
            <div className="error">{error}</div>
            <div className="m-4" />
          </>
        ) : (
          <></>
        )}

        <div className="row w-100">
          <Button className="f-1" color="brand-light" onClick={goBack}>
            Cancel
          </Button>
          <div className="m-4" />

          <Button className="f-1" onClick={submit}>
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProjectAdd;
