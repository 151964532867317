import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faCheckSquare,
  faCoffee,
  faHome,
  faCalendarAlt,
  faTimes,
  faLocationArrow,
  faCalendarPlus,
  faTrashAlt,
  faLink,
  faClock,
  faPencilAlt,
  faPhone,
  faPhoneAlt,
  faHouseDamage,
  faCalculator,
  faClipboard,
  faCube,
  faUser,
  faDollarSign,
  faUsers,
  faPlus,
  faUserPlus,
  faSync,
  faSyncAlt,
  faDragon,
  faMobileAlt,
  faMapMarkerAlt,
  faMapMarkedAlt,
  faNotesMedical,
  faGamepad,
  faSpinner,
  faCircleNotch,
  faGhost,
  faDiceD20,
  faCircle,
  faPassport,
  faEye,
  faDownload,
  faCog,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  fab,
  faCheckSquare,
  faCoffee,
  faHome,
  faCalendarAlt,
  faTimes,
  faLocationArrow,
  faCalendarPlus,
  faTrashAlt,
  faLink,
  faClock,
  faPencilAlt,
  faPhone,
  faPhoneAlt,
  faHouseDamage,
  faCalculator,
  faClipboard,
  faCube,
  faUser,
  faDollarSign,
  faUsers,
  faPlus,
  faUserPlus,
  faSync,
  faSyncAlt,
  faDragon,
  faMobileAlt,
  faMapMarkerAlt,
  faMapMarkedAlt,
  faNotesMedical,
  faGamepad,
  faSpinner,
  faCircleNotch,
  faGhost,
  faDiceD20,
  faCircle,
  faPassport,
  faEye,
  faDownload,
  faCog
);
