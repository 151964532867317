import React, { useState, useEffect, createRef, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Button, IconButton, Loading } from "./Components";
import "./IconLibrary";

import { domain } from "./Constants";

import QRCode from "qrcode.react";
import ar from "./ar";
import axios from "axios";

const File = ({ type, project }) => {
  const history = useHistory();

  const [file, setFile] = useState({ name: project[`${type}File`] });
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();

  const [error, setError] = useState("");

  const view = async () => {
    // TODO check device and hide button accordingly
    switch (type) {
      case "glb":
        window.location.href = `intent://arvr.google.com/scene-viewer/1.0?file=${domain}/api/files/${project.name}/${file.name}&mode=ar_preferred#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=${domain}/error.html;end;`;
        break;
      case "usdz":
        window.location.href = `${domain}/api/files/${project.name}/${file.name}`;
        break;
    }
  };

  const download = async () => {
    window.location.href = `${domain}/api/files/${project.name}/${file.name}`;
  };

  const remove = async () => {
    setLoading(true);
    try {
      await ar.delete("project/file", {
        data: { _id: project._id, type },
      });
      setFile({ ...file, name: "" });
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          {file.name ? (
            <>
              <div className="m-4" />
              <IconButton
                icon={type === "glb" ? ["fab", "android"] : ["fab", "apple"]}
                style={{
                  fontSize: 40,
                  padding: 16,
                  borderRadius: 8,
                  width: 77,
                  height: 77,
                }}
                onClick={view}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      )}
      {error ? (
        <>
          <div className="m-4" />
          <div className="error ">{error}</div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const ProjectRead = ({ match }) => {
  const history = useHistory();
  const location = useLocation();

  const [project, setProject] = useState(history.location.state?.project);

  const qr = createRef();

  const goBack = () => {
    history.location?.state?.back
      ? history.goBack()
      : history.replace("/projects");
  };

  const remove = async () => {
    try {
      await ar.delete("project/", {
        data: { _id: project._id },
      });
      goBack();
    } catch (err) {
      console.log(err);
    }
  };

  const getProject = async () => {
    try {
      const _id = location.pathname.split("/").slice(-2)[0];
      setProject((await ar.get(`project/${_id}`)).data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const state = location.state;
    if (state?.project) {
      delete state.project;
      history.replace({ ...location, state });
    } else {
      getProject();
    }
  }, []);

  return (
    <>
      {project ? (
        <div className="d-flex col start f-1">
          <div className="row w-100">
            <div className="f-1" />
            <div className="title">{project.name}</div>
            <div className="f-1" />
          </div>
          <File type="glb" project={project} />
          <div className="m-4" />
          <File type="usdz" project={project} />
          <div className="m-4" />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ProjectRead;
