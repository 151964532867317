import logo from "./logo.svg";
import "./App.css";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Login from "./Login";
import Projects from "./Projects";
import Project from "./Project";
import ProjectAdd from "./ProjectAdd";
import ProjectRead from "./ProjectRead";

const App = () => {
  return (
    <>
      <Router>
        <Route exact path="/login" component={Login}></Route>
        <Route exact path="/projects" component={Projects}></Route>
        <Route exact path="/project/:_id" component={Project} />
        <Route exact path="/project/:_id/read" component={ProjectRead} />
        <Route exact path="/projects/add" component={ProjectAdd}></Route>
      </Router>
    </>
  );
};

export default App;
