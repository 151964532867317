import Button from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconButton = ({ icon, ...props }) => {
  return (
    <Button {...props}>
      <FontAwesomeIcon icon={icon} />
    </Button>
  );
};

export default IconButton;
