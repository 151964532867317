import React from "react";

import "./Login.css";
import logo from "./logo.svg";

import { Button, TextInput } from "./Components";

const Login = () => {
  return (
    <div className="d-flex center col container">
      <img src={logo} alt="logo" />

      <TextInput placeholder="User" />
      <div className="m-4" />
      <TextInput placeholder="Password" />
      <div className="m-4" />
      <Button>Login</Button>
    </div>
  );
};

export default Login;
