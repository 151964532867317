import React, { useState, useEffect, createRef, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Button, IconButton, Loading } from "./Components";
import "./IconLibrary";

import { domain } from "./Constants";

import QRCode from "qrcode.react";
import ar from "./ar";
import axios from "axios";

const File = ({ type, project }) => {
  const history = useHistory();

  const [file, setFile] = useState({ name: project[`${type}File`] });
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();

  const [error, setError] = useState("");

  const handleChangeFile = async e => {
    setError("");
    const newFile = e.target.files[0];
    if (newFile.name.split(".").slice(-1)[0] !== type) {
      setError(`.${type} file required`);
    } else {
      setLoading(true);
      await upload(newFile);
      setLoading(false);
    }
  };

  const upload = async newFile => {
    try {
      const formData = new FormData();
      formData.append("_id", project._id);
      formData.append("file", newFile);
      await ar.post("project/upload/", formData);
      setFile(newFile);
    } catch (err) {
      console.log(err);
      setError("Upload error");
    }
  };

  const view = async () => {
    // TODO check device and hide button accordingly
    switch (type) {
      case "glb":
        window.location.href = `intent://arvr.google.com/scene-viewer/1.0?file=${domain}/api/files/${project.name}/${file.name}&mode=ar_preferred#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=${domain}/error.html;end;`;
        break;
      case "usdz":
        window.location.href = `${domain}/api/files/${project.name}/${file.name}`;
        break;
    }
  };

  const download = async () => {
    window.location.href = `${domain}/api/files/${project.name}/${file.name}`;
  };

  const remove = async () => {
    setLoading(true);
    try {
      await ar.delete("project/file", {
        data: { _id: project._id, type },
      });
      setFile({ ...file, name: "" });
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          <Button
            style={{ width: "200px" }}
            className="ellipsis"
            color={file.name ? "white" : "accent"}
            onClick={() => {
              inputRef.current.click();
              // console.log(file);
            }}
          >
            {file.name ? file.name : `Upload .${type}`}
          </Button>

          <input
            type="file"
            onChange={handleChangeFile}
            ref={inputRef}
            style={{ display: "none" }}
          />
          {file.name ? (
            <>
              <div className="m-4" />
              <IconButton icon="eye" onClick={view} />
              <div className="m-4" />
              <IconButton icon="download" onClick={download} />
              <div className="m-4" />
              <IconButton icon="trash-alt" onClick={remove} />
            </>
          ) : (
            <></>
          )}
        </div>
      )}
      {error ? (
        <>
          <div className="m-4" />
          <div className="error ">{error}</div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const Project = ({ match }) => {
  const history = useHistory();
  const location = useLocation();

  const [project, setProject] = useState(history.location.state?.project);

  const qr = createRef();

  const goBack = () => {
    history.location?.state?.back
      ? history.goBack()
      : history.replace("/projects");
  };

  const remove = async () => {
    try {
      await ar.delete("project/", {
        data: { _id: project._id },
      });
      goBack();
    } catch (err) {
      console.log(err);
    }
  };

  const getProject = async () => {
    try {
      const _id = location.pathname.split("/").slice(-1)[0];
      setProject((await ar.get(`project/${_id}`)).data);
    } catch (err) {
      console.log(err);
    }
  };

  const downloadQR = async () => {
    console.log("owo");
    const svg = document.getElementById("svg");
    const serializer = new XMLSerializer();
    // const source = serializer.serializeToString(svg);
    console.log({ svg, qr });
  };

  useEffect(() => {
    const state = location.state;
    if (state?.project) {
      delete state.project;
      history.replace({ ...location, state });
    } else {
      getProject();
    }
  }, []);

  return (
    <>
      {project ? (
        <div className="d-flex col start f-1">
          <div className="row w-100">
            <div className="f-1" />
            <div className="title">{`Project ${project.name}`}</div>
            <div className="f-1">
              <IconButton
                icon="trash-alt"
                className="circle mr-8"
                color="white"
                onClick={remove}
              />
            </div>
          </div>
          <File type="glb" project={project} />
          <div className="m-4" />

          <File type="usdz" project={project} />
          <div className="m-4" />
          {/* The QR code must appear if both glb and usdz are present */}
          {/* <QRCode
            value="https://ar.aonegames.com/trix"
            includeMargin
            size={256}
            // style={{ width: "256px", height: "256px" }}
            id="qr"
            renderAs="svg"
          /> */}
          {/* <div className="m-4" /> */}
          {/* <Button onClick={downloadQR}>Download QR</Button>
          <div className="m-4" /> */}
          <Button color="brand-light" onClick={goBack}>
            Cancel
          </Button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Project;
