import axios from "axios";
import { domain } from "./Constants";

const ar = axios.create({
  withCredentials: false,
  //   validateStatus: () => true,
});

ar.interceptors.request.use(
  async config => {
    //   let token = await getToken();
    // config.headers.common["Cookie"] = "auth=" + token;
    //   config.headers.common["Authorization"] = token;
    config.url = `${domain}/api/${config.url}`;
    return config;
  },
  error => Promise.reject(error)
);

export default ar;
