import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Loading.css";

const Loading = () => {
  return <FontAwesomeIcon className="loading" icon="cog" spin />;
};

export default Loading;
