import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Button, IconButton } from "./Components";

import ar from "./ar";

const Projects = () => {
  const history = useHistory();

  const [projects, setProjects] = useState([]);

  const getProjects = async () => {
    try {
      setProjects((await ar.get("project/")).data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div className="d-flex col start f-1">
      <div className="row w-100">
        <div className="f-1" />
        <div className="title">Projects</div>
        <div className="f-1">
          <IconButton
            icon="plus"
            className="circle mr-8"
            color="white"
            onClick={() => history.push("/projects/add", { back: true })}
          />
        </div>
      </div>
      <div className="d-flex wrap">
        {projects.map((value, index) => (
          <Button
            className="m-2"
            onClick={() =>
              history.push(`/project/${value._id}`, {
                project: { ...value },
                back: true,
              })
            }
            key={index}
          >
            {value.name}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default Projects;
