import React, { useState, useEffect, useContext } from "react";

import "./Button.css";

const Button = ({
  onClick,
  color = "accent",
  className = "",
  children,
  ...props
}) => (
  <button
    type="button"
    className={`btn btn-${color} ${className}`}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
);

export default Button;
